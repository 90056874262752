import type { IConsultationResponse } from "./chatTypes";
import type { DoctorDetailResponse, ISuccessConsultation } from "./consultationsTypes";
import type { IRecipeRequest, IRecipeResponse } from "./recipeTypes";

export enum PaymentsTypesEnum {
    SEASON = "season",
    PRESCRIPTION = "recipe",
    CONSULTATION = "consultation"
}

export type IPaymentInfo = {
    ip: string;
    price: number;
    doctor: DoctorDetailResponse;
    description: string;
    callbackUrl: string;
};

export type FormDataType = {
    card_number?: string;
    expires?: string;
    card_cvv2?: string;
    payer_email?: string;
    payer_phone?: string;
};

export interface IPaymentData {
    action: "SALE";
    order_id: string;
    order_amount: string;
    order_currency: "UAH";
    order_description: string;
    payer_ip: string; // ХХХ.ХХХ.ХХХ.ХХХ
    term_url_3ds: string;
    card_number: number;
    card_exp_month: string;
    card_exp_year: number;
    card_cvv2: number;
    payer_email: string;
    payer_phone: number;
    req_token: "Y" | "N";
    recurring_init: "Y" | "N";
    doctor_id?: string;
    type: PaymentsTypesEnum;
}

export type IPromoResponse = { codes: string[] };

interface IPlatonResponse {
    action: string;
    result: string;
    status: string;
    order_id: string;
    trans_id: string;
    trans_date: string;
    redirect_params?: string | null;
    redirect_method?: "GET";
    redirect_url?: string;
    decline_reason?: string;
    error_message?: string;
}

export interface PaymentResponse {
    data: IPlatonResponse;
    status: string;
}
