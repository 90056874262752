import type { FormDataType } from "../models/paymentTypes";

// Luhn algorithm
const luhnCheck = (cardNumber?: string): boolean => {
    if (!cardNumber) return false;

    const number = `${parseInt(cardNumber.replace(/\s+/g, ""), 10)}`;

    let sum = 0;
    let shouldDouble = false;

    for (let i = number.length - 1; i >= 0; i--) {
        let digit = parseInt(number[i], 10);

        if (shouldDouble) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }

        sum += digit;
        shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
};

export const validationCardFields = (values: FormDataType) => {
    const { card_number, card_cvv2, expires, payer_email, payer_phone } = values;
    let errors: FormDataType = {};

    // check card number
    const isValidCardNumber = luhnCheck(card_number);

    if (!isValidCardNumber) errors.card_number = "Номер картки не корректний";

    // check expires date
    if (!expires || !/^\d{2}\/\d{4}$/.test(expires)) errors.expires = "Введіть термін дії картки";

    if (expires) {
        const [monthStr, yearStr] = expires.split("/");

        const month = parseInt(monthStr, 10);
        const year = parseInt(yearStr, 10);

        if (month < 1 || month > 12) {
            errors.expires = "Перевірте термін дії картки";
        }

        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1;

        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            errors.expires = "Перевірте термін дії картки";
        }
    }

    // check card CVV
    const CVV = card_cvv2?.replaceAll("*", "");
    const cvvLength = CVV?.length ?? 0;

    if (cvvLength < 3) {
        errors.card_cvv2 = "Номер cvv не може бути меншим 3 символів";
    }

    // check email

    const emailReg =
        /^(?!.*[а-яА-ЯЁё])[a-zA-Z0-9]([a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/i;

    if (!payer_email || !emailReg.test(payer_email)) {
        errors.payer_email = "Введіть корректну email адресу";
    }

    // check phone number
    const phone = +(payer_phone ?? 0);
    const phoneLength = phone.toString().length;

    if (!payer_phone || phoneLength < 5) {
        errors.payer_phone = "Введіть корректний номер телефону";
    }

    return errors;
};
