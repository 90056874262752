import dayjs from "dayjs";
import { getUserFullName } from "../../helpers/getUserFullName";
import { UserRole } from "../../models/authTypes";
import type { IPaymentInfo } from "../../models/paymentTypes";
import { defaultPaymentInfo } from "../../pages/payment";
import { RoutesNames } from "../../routes/enum";
import PaymentService from "../../services/PaymentService";

export const getConsultationPaymentData = async (
    host: string,
    consultationId?: string,
    doctorId?: string
): Promise<IPaymentInfo> => {
    if (!consultationId || !doctorId) return defaultPaymentInfo;
    try {
        const { data: IPData } = await PaymentService.getIPAddress();
        const { data: orderData } = await PaymentService.getConsultationDetails(consultationId);
        const { data: doctorData } = await PaymentService.getDoctorInfo(doctorId);

        const description = `Оплата за надання консультаційних послуг до лікаря ${getUserFullName(
            doctorData
        )}, що відбудеться ${dayjs(+orderData.start).format("DD-MM-YYYY")}`;

        const callbackUrl = `${host}/${UserRole.PATIENT}/${RoutesNames.CONSULTATION}/${doctorId}/success?order=${consultationId}`;

        const data = {
            ip: IPData.ip,
            price: orderData.price,
            doctor: doctorData,
            description,
            callbackUrl
        };

        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getPrescriptionPaymentData = async (
    host: string,
    prescriptionId?: string,
    doctorId?: string
): Promise<IPaymentInfo> => {
    if (!prescriptionId || !doctorId) return defaultPaymentInfo;
    try {
        const { data: IPData } = await PaymentService.getIPAddress();
        const { data: doctorData } = await PaymentService.getDoctorInfo(doctorId);

        const description = `Оплата рецепту для лікаря ${getUserFullName(doctorData)}`;

        const callbackUrl = `${host}/${UserRole.PATIENT}/${
            RoutesNames.RECIPES + RoutesNames.RECIPE_ORDER
        }/${doctorId}/success?order=${prescriptionId}`;

        const data = {
            ip: IPData.ip,
            price: doctorData.prices.recipe_price || 0,
            doctor: doctorData,
            description,
            callbackUrl
        };

        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getSubscriptionPaymentData = async (
    host: string,
    subscriptionId?: string,
    doctorId?: string
): Promise<IPaymentInfo> => {
    if (!subscriptionId || !doctorId) return defaultPaymentInfo;
    try {
        const { data: IPData } = await PaymentService.getIPAddress();
        const { data: doctorData } = await PaymentService.getDoctorInfo(doctorId);

        const description = `Оплата абонeменту на візит до лікаря ${getUserFullName(doctorData)}`;

        const callbackUrl = `${host}/${UserRole.PATIENT}/${RoutesNames.FINANCE}?order=${subscriptionId}`;

        const data = {
            ip: IPData.ip,
            price: doctorData.prices.season_price || 0,
            doctor: doctorData,
            description,
            callbackUrl
        };

        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
